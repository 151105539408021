import React from "react"
import firstbornLogo from "../images/firstborn-logo.svg"
import "./footer.scss"

const Footer = ({type}) => {

  return (
    <div className={"footer"}>
        <img className={"footer__logo"} src={firstbornLogo}/>
        <div className={"footer__content"}>
            <p>32 Avenue of the Americas, 17<sup>th</sup> Floor New York, New York, 10013</p>
            <p>+1 (212) 574 5300 &nbsp;· &nbsp;<a href="https://www.firstborn.com/" target="_blank">firstborn.com</a></p>
        </div>
        {!type && <p className={"footer__privacy"}><a href="https://www.firstborn.com/privacy/" target="_blank">Privacy Policy</a></p>}
    </div>
  )
}
export default Footer