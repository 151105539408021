
import fav1 from "../images/favicon_frames/frame_apngframe01.png"
import fav2 from "../images/favicon_frames/frame_apngframe02.png"
import fav3 from "../images/favicon_frames/frame_apngframe03.png"
import fav4 from "../images/favicon_frames/frame_apngframe04.png"
import fav5 from "../images/favicon_frames/frame_apngframe05.png"
import fav6 from "../images/favicon_frames/frame_apngframe06.png"
import fav7 from "../images/favicon_frames/frame_apngframe07.png"
import fav8 from "../images/favicon_frames/frame_apngframe08.png"
import fav9 from "../images/favicon_frames/frame_apngframe09.png"

import fav11 from "../images/favicon_frames/frame_apngframe11.png"
import fav12 from "../images/favicon_frames/frame_apngframe12.png"
import fav13 from "../images/favicon_frames/frame_apngframe13.png"
import fav14 from "../images/favicon_frames/frame_apngframe14.png"
import fav15 from "../images/favicon_frames/frame_apngframe15.png"
import fav16 from "../images/favicon_frames/frame_apngframe16.png"
import fav17 from "../images/favicon_frames/frame_apngframe17.png"
import fav18 from "../images/favicon_frames/frame_apngframe18.png"
import fav19 from "../images/favicon_frames/frame_apngframe19.png"

import fav21 from "../images/favicon_frames/frame_apngframe21.png"
import fav22 from "../images/favicon_frames/frame_apngframe22.png"
import fav23 from "../images/favicon_frames/frame_apngframe23.png"
import fav24 from "../images/favicon_frames/frame_apngframe24.png"
import fav25 from "../images/favicon_frames/frame_apngframe25.png"
import fav26 from "../images/favicon_frames/frame_apngframe26.png"
import fav27 from "../images/favicon_frames/frame_apngframe27.png"
import fav28 from "../images/favicon_frames/frame_apngframe28.png"
import fav29 from "../images/favicon_frames/frame_apngframe29.png"

import fav31 from "../images/favicon_frames/frame_apngframe31.png"
import fav32 from "../images/favicon_frames/frame_apngframe32.png"
import fav33 from "../images/favicon_frames/frame_apngframe33.png"
import fav34 from "../images/favicon_frames/frame_apngframe34.png"
import fav35 from "../images/favicon_frames/frame_apngframe35.png"
import fav36 from "../images/favicon_frames/frame_apngframe36.png"
import fav37 from "../images/favicon_frames/frame_apngframe37.png"
import fav38 from "../images/favicon_frames/frame_apngframe38.png"
import fav39 from "../images/favicon_frames/frame_apngframe39.png"

import fav41 from "../images/favicon_frames/frame_apngframe41.png"
import fav42 from "../images/favicon_frames/frame_apngframe42.png"
import fav43 from "../images/favicon_frames/frame_apngframe43.png"
import fav44 from "../images/favicon_frames/frame_apngframe44.png"
import fav45 from "../images/favicon_frames/frame_apngframe45.png"
import fav46 from "../images/favicon_frames/frame_apngframe46.png"
import fav47 from "../images/favicon_frames/frame_apngframe47.png"
import fav48 from "../images/favicon_frames/frame_apngframe48.png"
import fav49 from "../images/favicon_frames/frame_apngframe49.png"

import fav51 from "../images/favicon_frames/frame_apngframe51.png"
import fav52 from "../images/favicon_frames/frame_apngframe52.png"
import fav53 from "../images/favicon_frames/frame_apngframe53.png"
import fav54 from "../images/favicon_frames/frame_apngframe54.png"
import fav55 from "../images/favicon_frames/frame_apngframe55.png"
import fav56 from "../images/favicon_frames/frame_apngframe56.png"
import fav57 from "../images/favicon_frames/frame_apngframe57.png"
import fav58 from "../images/favicon_frames/frame_apngframe58.png"
import fav59 from "../images/favicon_frames/frame_apngframe59.png"

import fav60 from "../images/favicon_frames/frame_apngframe60.png"

let fav = [
    fav1,
    fav2,
    fav3,
    fav4,
    fav5,
    fav6,
    fav7,
    fav8,
    fav9,
    fav11,
    fav12,
    fav13,
    fav14,
    fav15,
    fav16,
    fav17,
    fav18,
    fav19,
    fav21,
    fav22,
    fav23,
    fav24,
    fav25,
    fav26,
    fav27,
    fav28,
    fav29,
    fav31,
    fav32,
    fav33,
    fav34,
    fav35,
    fav36,
    fav37,
    fav38,
    fav39,
    fav41,
    fav42,
    fav43,
    fav44,
    fav45,
    fav46,
    fav47,
    fav48,
    fav49,
    fav51,
    fav52,
    fav53,
    fav54,
    fav55,
    fav56,
    fav57,
    fav58,
    fav59,
    fav60,
];

export default fav;